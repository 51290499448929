import React, { Component } from "react";

import { NavigationType } from "../../../src/types/NavigationType";
import { TabsNavigatorScreens } from "../../../src/navigation/TabsNavigator";
import { FooterButton } from "../../../src/components/footer/FooterButton";


interface Props {
    navigation: NavigationType;
    title: string;
}

interface State {}



export class CustomFotterButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }


    render() {
        return (
            <>
                <FooterButton icon={"wallet"} screen={"Wallets"} {...this.props} />
            </>
        );
    }
}
