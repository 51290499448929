import CircleButton from "@base/CircleButton";
import Container from "@base/Container";
import InlineButton from "@base/InlineButton";
import Row from "@base/Row";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { showPopupMessage } from "@store/actions/global";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import { VibrationType, vibrate } from "@utils/vibration/vibration.helper";
import { Camera, CameraView } from "expo-camera";
import * as Clipboard from "expo-clipboard";
import * as ImagePicker from "expo-image-picker";
import jsQR from "jsqr";
import React, { Component } from "react";
import { Platform, StyleSheet, View } from "react-native";

interface Props {
    navigation: NavigationType;
    rescan?: boolean;
    returnBarCodeScanned: (qrCode) => void;
    goBack?: () => void;
}

interface State {
    scanned: boolean;
}

const { t } = i18n;

export default class ScannerBase extends Component<Props, State> {
    intervalID;
    cameraRef;
    constructor(props: Props) {
        super(props);
        this.cameraRef = React.createRef();
        this.requestPermission = this.requestPermission.bind(this);
        this.scanQRCode = this.scanQRCode.bind(this);
        this.pasteCode = this.pasteCode.bind(this);
        this.handleBarCodeScanned = this.handleBarCodeScanned.bind(this);
        this.state = {
            scanned: false,
        };
    }

    componentDidMount() {
        this.requestPermission();
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    componentDidUpdate(prevProps): void {
        if (prevProps.rescan != this.props.rescan && this.props.rescan) {
            this.setState({ scanned: !this.props.rescan });
        }
    }

    async requestPermission() {
        if (Platform.OS == "web") {
            this.intervalID = setInterval(() => {
                this.webAppSnapshot();
            }, 1000);
            return;
        }

        await Camera.requestCameraPermissionsAsync();
        const permission = await Camera.getCameraPermissionsAsync();

        if (permission?.status !== "granted" && permission.canAskAgain) {
            store.dispatch(showPopupMessage({ type: "ERROR", message: t("camera_permission") }));
            await Camera.requestCameraPermissionsAsync();
        }

        if (permission?.status !== "granted" && !permission.canAskAgain) {
            store.dispatch(showPopupMessage({ type: "ERROR", message: t("camera_permission_null") }));
        }
        vibrate(VibrationType.SUCCESS);
    }

    goBack = () => {
        this.props.goBack ? this.props.goBack() : this.props.navigation.goBack();
    };

    handleBarCodeScanned(result) {
        if (this.state.scanned) return;
        this.setState({ scanned: true });
        this.props.returnBarCodeScanned(result);
        vibrate(VibrationType.SUCCESS);
    }

    async webAppSnapshot() {
        if (!this.cameraRef.current) return;
        if (this.state.scanned) return;
        try {
            const options = { quality: 1, base64: true };
            const snapshot = await this.cameraRef.current.takePictureAsync(options);
            this.scanQRCode(snapshot.base64);
        } catch (e) {}
    }

    async scanQRCode(snapshot) {
        if (snapshot) {
            const image = new Image();
            image.src = snapshot;
            image.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                const context = canvas.getContext("2d");
                context.drawImage(image, 0, 0);
                const imageData = context.getImageData(0, 0, image.width, image.height);
                const code = jsQR(imageData.data, imageData.width, imageData.height);

                console.log(imageData);
                if (code) {
                    this.handleBarCodeScanned(code.data);
                }
            };
        }
    }

    selectImageWithQR = async () => {
        ImagePicker.requestMediaLibraryPermissionsAsync();
        let QRpickerResult: any = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            base64: false,
            quality: 0.8,
        });

        if (QRpickerResult.assets[0].uri) {
            try {
                const QR =
                    Platform.OS == "web"
                        ? this.scanQRCode(QRpickerResult.assets[0].uri)
                        : await Camera.scanFromURLAsync(QRpickerResult.assets[0].uri);
                if (QR) {
                    const data = QR[0].data;
                    this.handleBarCodeScanned(data);
                }
            } catch {}
        }
    };

    async pasteCode() {
        const pasteText: string = await Clipboard.getStringAsync();

        if (pasteText !== undefined && pasteText?.length > 1) {
            this.handleBarCodeScanned(pasteText);
        }
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <CameraView
                    ref={this.cameraRef}
                    onBarcodeScanned={(qr) => this.handleBarCodeScanned(qr?.data)}
                    style={styles.scanner}
                    facing={ImagePicker.CameraType.back}
                    //@ts-ignore
                    type={ImagePicker.CameraType.back}
                >
                    <Container
                        style={{
                            flex: 1,
                            position: "absolute",
                            top: 20,
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Row>
                            <CircleButton
                                style={{ top: 0 }}
                                iconSize={26}
                                icon={"clipboard"}
                                onPress={this.pasteCode}
                            />
                            <CircleButton style={{ top: 0 }} icon={"images"} onPress={this.selectImageWithQR} />
                        </Row>
                    </Container>

                    <InlineButton title={t("back")} onPress={this.goBack} style={styles.button} />
                    <View style={styles.maskWrapper}>
                        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <View style={styles.maskTopLeft}></View>
                            <View style={styles.maskTopRight}></View>
                        </View>

                        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <View style={styles.maskBottomLeft}></View>
                            <View style={styles.maskBottomRight}></View>
                        </View>
                    </View>
                </CameraView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        backgroundColor: colors.background,
        padding: 0,
        paddingHorizontal: 0,
        paddingLeft: 0,
        paddingStart: 0,
        marginHorizontal: 0,
        marginLeft: 0,
        marginStart: 0,
    },
    scanner: {
        backgroundColor: "black",
        left: 0,
        right: 0,
        bottom: 0,
        padding: 0,
        margin: 0,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        height: "100%",
    },
    button: {
        position: "absolute",
        bottom: 50,
    },
    maskWrapper: {
        position: "absolute",
        width: 300,
        height: 300,
        justifyContent: "space-between",
    },
    maskTopLeft: {
        position: "relative",
        top: 0,
        bottom: 0,
        left: 0,
        width: 50,
        height: 50,
        borderTopLeftRadius: 10,
        borderLeftColor: "white",
        borderLeftWidth: 2,
        borderTopColor: "white",
        borderTopWidth: 2,
    },
    maskTopRight: {
        position: "relative",
        top: 0,
        bottom: 0,
        right: 0,
        width: 50,
        height: 50,
        borderTopRightRadius: 10,
        borderRightColor: "white",
        borderRightWidth: 2,
        borderTopColor: "white",
        borderTopWidth: 2,
    },
    maskBottomLeft: {
        position: "relative",
        top: 0,
        bottom: 0,
        left: 0,
        width: 50,
        height: 50,
        borderBottomLeftRadius: 10,
        borderLeftColor: "white",
        borderLeftWidth: 2,
        borderBottomColor: "white",
        borderBottomWidth: 2,
    },
    maskBottomRight: {
        position: "relative",
        top: 0,
        bottom: 0,
        right: 0,
        width: 50,
        height: 50,
        borderBottomRightRadius: 10,
        borderRightColor: "white",
        borderRightWidth: 2,
        borderBottomColor: "white",
        borderBottomWidth: 2,
    },
});
