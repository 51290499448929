import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import LottieBase from "@components/animations/Lottie";
import SimpleCard from "@components/cards/SimpleCard";
import i18n from "@i18n/i18n";
import { colors } from "@styles/globalStyles";
import Constants from "expo-constants";
import React, { Component } from "react";
import { Linking, StyleSheet, View } from "react-native";
import { connect } from "react-redux";

const { t } = i18n;

interface Props {
    maintenanceMode: boolean;
}

interface State {
    showMaintenanceMode;
}

export class _MaintenanceMode extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showMaintenanceMode: this.props.maintenanceMode,
        };
    }

    componentDidUpdate() {
        if (this.props.maintenanceMode && !this.state.showMaintenanceMode) {
            this.setState({ showMaintenanceMode: true });
        }
    }

    render() {
        return (
            <View style={this.state.showMaintenanceMode ? styles.wrapper : styles.hidden}>
                <View style={styles.wrapper && styles.background}></View>
                <View style={{ flex: 1, paddingTop: 100, alignContent: "center", alignItems: "center" }}>
                    <View
                        style={{
                            backgroundColor: colors.shadow,
                            height: 260,
                            width: 260,
                            borderRadius: 130,
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <LottieBase source={require("../../assets/animations/maintence.json")}></LottieBase>
                    </View>

                    <View style={{ flex: 1, paddingHorizontal: 20, paddingTop: 60, maxWidth: 600 }}>
                        <BoldText fontSize={22}>
                            {t("maintenance_mode_bold", { name: Constants.expoConfig?.name })}{" "}
                        </BoldText>
                        <RegularText style={{ paddingTop: 20, paddingBottom: 60 }} fontSize={18}>
                            {t("maintenance_mode_regular")}
                        </RegularText>

                        <SimpleCard
                            title={t("support")}
                            icon="life-preserver"
                            onPress={() => {
                                Linking.openURL(Constants.expoConfig?.extra?.support || "mailto:support@beexo.com");
                            }}
                        />
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    hidden: {
        display: "none",
    },
    wrapper: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 999999,
    },
    background: {
        position: "absolute",
        backgroundColor: Constants.expoConfig?.extra?.backgroundColor || colors.primary,
        opacity: 0.9,
        height: "100%",
        width: "100%",
    },
    icon: {
        fontSize: 80,
        zIndex: 999,
    },
});

const mapStateToProps = (state) => {
    return { maintenanceMode: state.global.maintenanceMode };
};

const mapDispatchToProps = (dispatch) => ({});

const MaintenanceMode = connect(mapStateToProps, mapDispatchToProps)(_MaintenanceMode);

export default MaintenanceMode;
