import BoldText from "@base/BoldText";
import Container from "@base/Container";
import RegularText from "@base/RegularText";
import LottieBase from "@components/animations/Lottie";
import SimpleCard from "@components/cards/SimpleCard";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { ForceUpdateNavigatorScreens } from "@navigation/ForceUpdateNavigator";
import { SeedNavigatorScreens } from "@navigation/SeedNavigator";
import { setAuthentication } from "@store/actions/global";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import AppStorage from "@utils/storage";
import Constants from "expo-constants";
import React, { Component } from "react";
import { Linking, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";

const { t } = i18n;

interface Props {
    navigation: NavigationType;
}

interface State {}

export class _ForceUpdateScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.updateApp = this.updateApp.bind(this);
        this.navigateToAccounts = this.navigateToAccounts.bind(this);
        this.state = {};
    }

    updateApp() {
        const url = Platform.select({
            web: "",
            ios: "itms-apps://itunes.apple.com/app/idTU_APPLE_ID",
            android: "market://details?id=${}",
        });
        Linking.openURL(url);
    }

    async navigateToAccounts() {
        const authentication = await AppStorage.getItem("authentication");

        if (authentication) {
            store.dispatch(setAuthentication(authentication));
        }
        this.props.navigation.navigate(ForceUpdateNavigatorScreens.Accounts.routeName, {
            screen: SeedNavigatorScreens.Seed.routeName,
            params: { restrictedOptions: true },
        });
        // this.props.navigation.navigate(ForceUpdateNavigatorScreens.Accounts.routeName, {restrictedOptions: true});
    }

    render() {
        return (
            <ScreenWrapper>
                <Container
                    style={{
                        flex: 1,
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        paddingTop: 50,
                    }}
                >
                    <View
                        style={{
                            backgroundColor: colors.shadow,
                            height: 200,
                            width: 200,
                            borderRadius: 100,
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <LottieBase loop={true} source={require("../../assets/animations/warning.json")}></LottieBase>
                    </View>

                    <View style={{ paddingTop: 60 }}>
                        <BoldText align={"center"} fontSize={20}>
                            {t("force_update_mode_title")}
                        </BoldText>
                        <RegularText align={"center"} style={{ paddingTop: 20, paddingBottom: 60 }} fontSize={16}>
                            {t("force_update_mode_description", { name: Constants.expoConfig?.name })}{" "}
                        </RegularText>

                        <SimpleCard
                            title={`${t("update")} ${Constants.expoConfig?.name}`}
                            icon="download-cloud"
                            onPress={this.updateApp}
                        />

                        <SimpleCard
                            title={`${t("accounts")}`}
                            icon="person-profile"
                            onPress={this.navigateToAccounts}
                        />

                        <SimpleCard
                            title={t("support")}
                            icon="life-preserver"
                            onPress={() => {
                                Linking.openURL(Constants.expoConfig?.extra?.support || "mailto:support@beexo.com");
                            }}
                        />
                    </View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    hidden: {
        display: "none",
    },
    wrapper: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 999999,
    },
    background: {
        position: "absolute",
        backgroundColor: Constants.expoConfig?.extra?.backgroundColor || colors.primary,
        opacity: 0.9,
        height: "100%",
        width: "100%",
    },
    icon: {
        fontSize: 80,
        zIndex: 999,
    },
});

const mapStateToProps = (state) => {
    return { forceUpdateMode: state.global.forceUpdateMode };
};

const mapDispatchToProps = (dispatch) => ({});

const ForceUpdateScreen = connect(mapStateToProps, mapDispatchToProps)(_ForceUpdateScreen);

export default ForceUpdateScreen;
